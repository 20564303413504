<template>
  <div>
    <h2>Selamat datang di admin dashboard</h2>

    <div id="wrapper-chart-total-pemilih">
      <header>
        <h2>Jumlah Pemilih Perangkatan</h2>
        <h2>Total Vote Saat Ini: {{ totalVotesEveryYears.data.all }}</h2>
      </header>
      <hr>
      <div v-if="totalVotesEveryYears.fetching === 'pending'">
        loading ....
      </div>
      <section v-if="totalVotesEveryYears.fetching === 'resolved'">
        <ChartTotalAllVoteEveryYearVue
          :total-votes="totalVotes"
          :labels="labels"
        />
      </section>
    </div>
  </div>
</template>

<style scoped>
#wrapper-chart-total-pemilih {
  border-radius: 20px;
  background: #FFFFFF;
  width: 100%;
}

#wrapper-chart-total-pemilih header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
  margin-top: 16px;
}

#wrapper-chart-total-pemilih header h2 {
  font-weight: 600;
  /* identical to box height */

  text-align: center;
  color: #5E5873;
  font-size: 18px;

}

#wrapper-chart-total-pemilih hr {
  margin: 0 16px
}

#wrapper-chart-total-pemilih section {
  padding: 16px;
}

</style>

<script>
import API from '@/api'
import ChartTotalAllVoteEveryYearVue from '@/components/ChartTotalAllVoteEveryYear/ChartTotalAllVoteEveryYear.vue'

export default {
  components: {
    ChartTotalAllVoteEveryYearVue,
  },
  data() {
    return {
      totalVotesEveryYears: {
        data: {
          all: 0,
          group: {},
        },
        fetching: 'idle',
      },
    }
  },
  computed: {
    labels() {
      return Object.keys(this.totalVotesEveryYears.data.group)
    },
    totalVotes() {
      return Object.values(this.totalVotesEveryYears.data.group)
    },
  },
  created() {
    this.getTotalVotesEveryYear()
  },
  methods: {
    async getTotalVotesEveryYear() {
      try {
        this.totalVotesEveryYears.fetching = 'pending'
        const { data } = await API.master.getCountAllVote()
        this.totalVotesEveryYears.data = data
        this.totalVotesEveryYears.fetching = 'resolved'
      } catch (error) {
        this.totalVotesEveryYears.fetching = 'rejected'
      }
    },
  },
}
</script>

<style>
</style>
